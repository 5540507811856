<template>
  <div class="container_monitors">
    <b-container class="py-5 px-4 px-md-5">
      <b-row class="mb-3 pt-lg-4">
        <b-col cols="12" class="mb-lg-4 title">
            <h3 class="fw-bold">New monitor</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            </p>
          </b-col>
      </b-row>
      <b-row align-h="center" class="h-100">
        <b-col cols="12" md="8" lg="6" xl="5" align-self="center">
          <form @submit.prevent="sendForm">
            <b-card no-body class="py-4 py-md-5 px-0 px-md-4">
              <b-container>
                <b-row>
                  <b-col>
                    <!-- Name -->
                    <b-form-group label="Name" label-for="name" class="text-start mb-3">
                      <b-form-input id="name" type="text" placeholder="Name"></b-form-input>
                    </b-form-group>
                    <!-- Base URL -->
                    <b-form-group label="Base URL" label-for="base" class="text-start mb-3">
                      <b-form-input id="base" type="text" placeholder="Base URL"></b-form-input>
                    </b-form-group>
                    <!-- Frequency -->
                    <b-form-group label="Frequency" label-for="frequency" class="text-start mb-3">
                      <b-form-select v-model="form.frequency" :options="frequency_options" id="frequency" class="form-select"></b-form-select>
                    </b-form-group>
                    <!-- Measure -->
                    <b-form-group label="Measure" label-for="measure" class="text-start mb-3">
                      <b-form-select v-model="form.measure" :options="measure_options" id="measure" class="form-select"></b-form-select>
                    </b-form-group>
                    <!-- Button -->
                    <div class="d-flex">
                      <b-button variant="secondary" type="submit" class="mt-lg-3 px-5 ms-auto">Save</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          measure: null,
          frequency: null,
        },
        measure_options: [
          { value: null, text: 'Measure' },
          { value: 'Measure #1', text: 'Measure #1' }
        ],
        frequency_options: [
          { value: null, text: 'Frequency' },
          { value: 'Frequency #1', text: 'Frequency #1' }
        ],
        load: false,
      }
    },
  }
</script>
